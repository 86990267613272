import './App.css';
import { useState, useEffect} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import './Subscribe.js'
import Subscribe from './Subscribe.js';

const statementlist = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eight",
  "Nineth",
  "Tenth",
  "Eleventh",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
]

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function SideBlock(props) {
  return (
    <div className='block'>
      <h2>{props.statement}</h2>
    </div>
  )
}

function SideRow(props) {
  return (
    <div className='siderow'>
      {props.statementRow.map((statement) => (
        <SideBlock statement={statement} />
      ))}
    </div>
  )
}


function Side() {

  let numberOfBlocks = 0;
  if (useMediaQuery('(min-width: 450px)')) numberOfBlocks = 1;
  if (useMediaQuery('(min-width: 750px)')) numberOfBlocks = 2;
  if (useMediaQuery('(min-width: 1050px)')) numberOfBlocks = 3;
  if (useMediaQuery('(min-width: 1350px)')) numberOfBlocks = 4;
  if (useMediaQuery('(min-width: 1650px)')) numberOfBlocks = 5;
  if (useMediaQuery('(min-width: 1950px)')) numberOfBlocks = 6;

  let numberOfRows = 0;
  if (useMediaQuery('(min-height: 250px)')) numberOfRows = 1;
  if (useMediaQuery('(min-height: 400px)')) numberOfRows = 2;
  if (useMediaQuery('(min-height: 550px)')) numberOfRows = 3;
  if (useMediaQuery('(min-height: 700px)')) numberOfRows = 4;
  if (useMediaQuery('(min-height: 850px)')) numberOfRows = 5;
  if (useMediaQuery('(min-height: 1000px)')) numberOfRows = 6;

  let statements = [];
  let statementRow = [];
  for (let j = 1; j < numberOfRows; j++) {
    for (let i = 1; i < numberOfBlocks; i++) {
      statementRow.push(statementlist[Math.floor(Math.random() * statementlist.length)]);
    }
    statements.push(statementRow);
    statementRow = [];
  }
  return (
    <div className='side'>
      {statements.map((statementRow) => (
        <SideRow statementRow={statementRow}/>
      ))}
    </div>
  );
}


function Center(props) {
  return (
    <div className='center'>
      <h2>NBO Club</h2>
      <p>Take your investments to the next level.</p>
      <button onClick={props.joinnowHandler}>Join Now</button>
    </div>
  );
}

function Banner(props) {
  return (
    <div className='banner'>
    <Side />
    <Center joinnowHandler={props.joinnowHandler}/>
    <Side />
    </div>
  );
}

function TopBar(props) {
  return (
    <div className='topbar'>
      <h1> NBO Club </h1>
      <button onClick={props.loginHandler}>Log In</button>
    </div>
  )
}

function LogIn() {
  return (
    <div className='over'>
      <h2>Log In</h2>
      <form>
      <label for="email">E-Mail:</label><br/>
        <input type="email" id='email' name='email'/><br/>
        <label for="pwd">Password:</label><br/>
        <input type="password" id="pwd" name="pwd"/><br/>
        <input type="submit" />
      </form>
    </div>
  )
}

function JoinNow() {
  return (
    <div className='over'>
      <h2>Join Now</h2>
      <form>
        <label for="email">E-Mail:</label><br/>
        <input type="email" id='email' name='email'/><br/>
        <label for="username">Username:</label><br/>
        <input type="text" id="username" name="username"/><br/>
        <label for="pwd">Password:</label><br/>
        <input type="password" id="pwd" name="pwd"/><br/>
        <label for="pwdconf">Confirm Password:</label><br/>
        <input type="password" id='pwdconf' name='pwdconf'/>
        <input type="submit" />
      </form>
    </div>
  )
}

function Checkout() {
  return (
    <div className='over'>
      <Subscribe />
    </div>
  )
}

function App() {
  let logincomponent = <LogIn />;
  let joinnowcomponent = <JoinNow />;
  let checkoutcomponent = <Checkout />;

  const [showLogin, setShowLogin] = useState(false);
  const [showJoinnow, setShowJoinnow] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  
  const handleShowLogin = event => {
    setShowLogin(current => !current);
  }
  const handleShowJoinnow = event => {
    setShowJoinnow(current => !current);
  }
  const handleShowCheckout = event => {
    setShowCheckout(current => !current);
  }

  let topbarcomponent = <TopBar loginHandler={handleShowLogin}/>;
  let bannercomponent = <Banner joinnowHandler={handleShowJoinnow}/>

  return (
    <div className='main'>
      {(showLogin || showJoinnow || showCheckout) && <div className='block-background'/>}
      {showLogin && logincomponent}
      {showJoinnow && joinnowcomponent}
      {showCheckout && checkoutcomponent}
      {topbarcomponent}
      <button onClick={handleShowCheckout} />
      {bannercomponent}
    </div>
  );
}

export default App;
