import { loadStripe } from '@stripe/stripe-js'

function Subscribe () {
    const handleClick = async e => {
        console.log("ok");
        const stripe = await loadStripe('pk_live_51KnVKmLleML64x2nUTtp22FrNFf6FieZIxR6qeWMXDl3Vj0THJ0H69NUtE0EWpmesu0z7vQJaueDrrCKRpbksIzT00fVRnUaT6');
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{
                price: 'price_1LxFzsLleML64x2nHJi4DZt8',
                quantity: 1
            }],
            mode: 'subscription',
            successUrl: 'https://www.nboclub.com',
            cancelUrl: 'https://www.nboclub.com'
        })
    }


    return <button onClick={handleClick}>Subscribe</button>
}

export default Subscribe;

